import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryType } from '../interface/query-type.enum';
import { Subtitle } from '../interface/subTitle';

@Component({
  selector: 'app-chat-test',
  templateUrl: './chat-test.component.html',
  styleUrls: ['./chat-test.component.css']
})
export class ChatTestComponent {

  @ViewChild('chatChannel', { static: false }) chatChannel!: ElementRef; // Correctly include the static option
  // Variables for Introduction
  introductionAudioUrl: string = '';
  subtitleUrl: string = '';
  isRecordingIntroductionAllowed: boolean = false;
  isRecordingIntroduction: boolean = false;
  loaderMessage: string = 'Analyzing text...';
  aiTalk: boolean = false;

  lectureStarted: boolean = false;
  selectedCourse: string = '';
  isQueryRecording: boolean = false;
  erroResponse: boolean = false;
  queryAudio: string = '';

  errorMessageText: string = '';
  mediaRecorder: MediaRecorder | null = null;
  introRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  isQueryFinished: boolean = false;
  lecturePausedTime: number = 0;
  lectureAudioElement: HTMLAudioElement | null = null;
  queryAudioElement: HTMLAudioElement | null = null;
  replyAudioElement: HTMLAudioElement | null = null;
  isProcessing: boolean = false;
  chatMessages: ChatMessage[] = [];
  loaderTextIndex: number = 0;

  queryLoaderMessages: string[] = [
    'Processing your audio...',
    'Understanding your query...',
    'Generating a response...',
    'Analyzing audio data...'
  ];
  sectionsImageMap: string[] = [
    'assets/tutor/answer_1.png',
    'assets/tutor/answer_2.png',
    'assets/tutor/answer_3.png'
  ]
  // sections: string[] = [
  //   "What is Python",
  //   // "Python Environment Setup & Essentials",
  //   "Python Basics: Language Constructs",
  //   "Operators in Python"
  //   // "Working with Lists",
  //   // "Understanding Tuples",
  //   // "Sets and Frozen Sets",
  //   // "Dictionaries in Python",
  //   // "Program Control Flow in Python",
  //   // "NumPy: Mathematical Computing",
  //   // "SciPy: Scientific Computing",
  //   // "Matplotlib: Data Visualization",
  //   // "Pandas: Data Analysis and Machine Learning"
  // ];

  sections: SectionModule[] = [
      {
        "module_name": "Python Language Basic Constructs",
        "submodules": [
          "Built-in data types in Python",
          "Learn classes, modules, Str (String), Ellipsis Object, Null Object, Ellipsis, Debug in Python",
          "Basic operators: comparison, arithmetic, slicing and slice operator, logical, bitwise in Python",
          "Loop and control statements: while, for, if, break, else, continue in Python",
          "Writing your first Python program",
          "Writing a Python function (with and without parameters) in Python",
          "Using the Lambda expression in Python",
          "Writing a class in Python",
          "Creating a member function and a variable in Python",
          "Creating an object in Python",
          "Writing a for loop in Python"
        ]
      },
      {
        "module_name": "Operators in Python",
        "submodules": [
          "Arithmetic Operators in Python",
          "Relational Operators in Python",
          "Logical Operators in Python",
          "Membership Operators in Python",
          "Identity Operators in Python",
          "Bitwise Operators in Python",
          "Assignment Operators in Python",
          "Operators Precedence in Python",
          "Evaluating Expression in Python",
          "Type Casting in Python"
        ]
      },
      {
        "module_name": "List",
        "submodules": [
          "Introduction to Python List in Python",
          "Creating List in Python",
          "Accessing List in Python",
          "Joining List in Python",
          "Replicating List in Python",
          "List Slicing in Python"
        ]
      },
      {
        "module_name": "Tuples",
        "submodules": [
          "Introduction to Tuple in Python",
          "Creating Tuples in Python",
          "Accessing Tuples in Python",
          "Joining Tuples in Python",
          "Replicating Tuples in Python",
          "Tuple Slicing in Python"
        ]
      },
      {
        "module_name": "Set and Frozen Set",
        "submodules": [
          "Introduction to Set and Frozenset in Python",
          "Creating Set and Frozenset in Python",
          "Accessing and Joining in Python",
          "Replicating and Slicing in Python"
        ]
      },
      {
        "module_name": "Dictionaries",
        "submodules": [
          "Introduction to Dictionary in Python",
          "Accessing values in dictionaries in Python",
          "Working with dictionaries in Python",
          "Properties in Python"
        ]
      },
      {
        "module_name": "Program Control Flow",
        "submodules": [
          "Conditional Statements in Python",
          "The if Statement in Python",
          "The if-else Statement in Python",
          "The if-elif Statement in Python",
          "Nested if Statements in Python",
          "Python Indentation in Python",
          "Looping and Iteration in Python",
          "The For Loop in Python",
          "The While Loop in Python",
          "Loop else Statement in Python",
          "Nested Loops in Python",
          "Break and Continue in Python",
          "The Range Function in Python",
          "Introduction to range() in Python",
          "Types of range() function in Python",
          "Use of range() function in Python"
        ]
      },
      {
        "module_name": "Numpy for Mathematical Computing",
        "submodules": [
          "Introduction to arrays and matrices in Python",
          "Broadcasting of array math, indexing of array in Python",
          "Standard deviation, conditional probability, correlation, and covariance in Python",
          "How to import a NumPy module in Python",
          "Creating an array using ND-array in Python",
          "Calculating standard deviation on an array of numbers in Python",
          "Calculating correlation between two variables in Python"
        ]
      },
      {
        "module_name": "Scipy for Scientific Computing",
        "submodules": [
          "Introduction to SciPy in Python",
          "Functions building on top of NumPy: cluster, linalg, signal, optimize, integrate, subpackages, SciPy with Bayes Theorem in Python",
          "Importing of SciPy in Python",
          "Applying the Bayes theorem on the given dataset in Python"
        ]
      },
      {
        "module_name": "Matplotlib for Data Visualization",
        "submodules": [
          "plot() using Functional approach in Python",
          "multi-plot using subplot() in Python",
          "plt.figure() using OO API Methods in Python",
          "add_axes(), set_xlabel(), set_ylabel(), set_title() Methods in Python",
          "Customization – figure size, improving dpi, Plot appearance, in Python",
          "Markers, Control over axis appearance and special Plot Types in Python"
        ]
      },
      {
        "module_name": "Pandas for Data Analysis & Machine Learning",
        "submodules": [
          "Introduction to Python dataframes in Python",
          "Importing data from JSON, CSV, Excel, SQL database, NumPy array to dataframe in Python",
          "Various data operations like selecting, filtering, sorting, viewing, joining, combining in Python",
          "Working on importing data from JSON files in Python",
          "Selecting a record by a group in Python",
          "Applying filter on top viewing records in Python"
        ]
      }
    ]

  

  userMessage: string = '';

  // New variables for lecture content
  currentLectureIndex: number = 0;
  lectureParts: { audio: string; text: string; moduleTitle: string, subModuleTitle: string, subtitleUrl: string }[] = [];
  currentLectureAudio: string | null = null;

  private loaderInterval: any;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  onCourseSelect(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedCourse = selectedValue;
  }


  async ngOnInit() {
    try {
      const response = await fetch(
        `${environment.AWS_MICRO_SERVICE_API}introduce_tutor`,
        { method: 'POST' }
      );
      if (response.ok) {
        const data = await response.json();

        // Extract the introduction audio and text
        const { audio_url, introduction_text, subtitle_path } = data;
        this.introductionAudioUrl =
          environment.AWS_MICRO_SERVICE_API + audio_url || '';
        this.subtitleUrl = subtitle_path;
      } else {
        console.error('Failed to fetch introduction tutor data:', response.statusText);
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
    }
  }

  async playIntroduction(): Promise<void> {
    this.pauseLecture();
    await this.loadSubtitles(this.subtitleUrl);;
    this.removeEventListenerForSubtitle(this.queryAudioElement);
    this.playQueryResponse(this.introductionAudioUrl, QueryType.Intro, 0, null);
    this.addEventListenerForSubtitle(this.queryAudioElement);
    this.cdr.detectChanges();
    // Wait for introduction audio to finish
    if (this.queryAudioElement) {
      this.queryAudioElement.onended = () => {
        this.isRecordingIntroductionAllowed = true;
        this.isRecordingIntroduction = false;
        this.aiTalk = false;
        this.cdr.detectChanges();
        this.startSelfIntro();
      };
    }
  }

  async startRecording(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.pauseLecture();
      this.isQueryRecording = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.chatMessages.push({
          text: "AUDIO FILE UPLOADED",
          type: "audio",
          isSent: true,
          replied: false,
          visited: false,
          repliedTo: null
        });
        this.scrollToBottom();
        this.cdr.detectChanges();

        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, false, this.currentLectureIndex, false);
        this.isQueryRecording = false;
        this.isRecordingIntroduction = false;
        this.isRecordingIntroductionAllowed = false;
        this.cdr.detectChanges();
      };

      this.mediaRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isQueryRecording = false;
    }
  }

  async startSelfIntro(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.isRecordingIntroduction = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.introRecorder = new MediaRecorder(stream);

      this.introRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.introRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, true, 0, false);
        this.isRecordingIntroduction = false;
        this.introRecorder = null;

        this.cdr.detectChanges();
      };

      this.introRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isRecordingIntroduction = false;
    }
  }

  stopIntroRecording(): void {
    if (this.introRecorder && this.introRecorder.state === 'recording') {
      // this.isRecordingIntroductionAllowed = false;
      this.isRecordingIntroduction = false;
      this.introRecorder.stop();
    }
  }

  stopRecording(): void {
    this.isQueryRecording = false;
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop();
    }
  }
  userConfirmed: boolean = false;
  async sendAudioToApi(audioBlob: Blob, chatQuery: ChatMessage, isForIntro: boolean, lectureNumber: number, userConfirmation: boolean): Promise<void> {
    try {
      this.isProcessing = true;
      this.cdr.detectChanges();
      const formData = new FormData();
      let bodyContent;
      let headers = {};
      if (audioBlob != null) {
        formData.append('audio', audioBlob, 'query_' + Date.now() + '.webm');
        bodyContent = formData;
      } else {
        const jsonPayload = {
          query: chatQuery.text,
        };
        bodyContent = JSON.stringify(jsonPayload);
        headers['Content-Type'] = 'application/json';
      }

      const request = isForIntro
        ? `${environment.AWS_MICRO_SERVICE_API}get_user_name` :
        userConfirmation ? `${environment.AWS_MICRO_SERVICE_API}confirmation`
          : `${environment.AWS_MICRO_SERVICE_API}handle_query`;

      const response = await fetch(request, {
        method: 'POST',
        headers,
        body: bodyContent,
      });


      if (response.ok) {
        this.isProcessing = false;
        const data = await response.json();
        this.queryAudio = environment.AWS_MICRO_SERVICE_API + (data.audio_url || '');
        this.subtitleUrl = data.subtitle_path;
        if (chatQuery != null && chatQuery.text != '') {
          this.chatMessages.push({
            replied: true,
            isSent: false,
            text: data.response_text || data.welcome_text || "Thanks for your input!",
            type: "text",
            visited: true,
            repliedTo: chatQuery.text
          });
        }


        this.userConfirmed = false; //reset
        if (userConfirmation) {
          this.userConfirmed = data.response_text;
        }

        this.scrollToBottom();
        this.cdr.detectChanges();
        if (!this.userConfirmed) {
          this.userConfirmed = false;
          await this.loadSubtitles(this.subtitleUrl);;
          this.removeEventListenerForSubtitle(this.queryAudioElement);
          await this.playQueryResponse(this.queryAudio, isForIntro ? QueryType.Intro : QueryType.ChatDoubt, lectureNumber, chatQuery);
          this.addEventListenerForSubtitle(this.queryAudioElement);
        }
        this.queryAudio = '';
        this.cdr.detectChanges();
      } else {
        this.isProcessing = false;
        console.error('API error:', response.statusText);
      }
    } catch (error) {
      this.errorMessageText = `Failed to generate response. Please try again.`;
      this.erroResponse = true;
      this.isProcessing = false;
      this.cdr.detectChanges();
      console.error('Failed to send audio to API:', error);
    }
  }
  startLectureClicked = false;
  async startLecture(): Promise<void> {
    this.startLectureClicked = true;
    this.playIntroduction();
    this.cdr.detectChanges();
    this.generateLectureParts(0, this.sections.length);
  }

  subModule: number = 0;
  async generateLectureParts(startIndex: number, endIndex: number): Promise<void> {
    for (let index = startIndex; index < endIndex; index++) {
      this.subModule = 0;
      let subModules = this.sections[index].submodules;

      try {
        while(this.subModule < subModules.length) {
          const jsonPayload = {
            section: subModules[this.subModule],
          };
        const response = await fetch(`${environment.AWS_MICRO_SERVICE_API}generate_lecture`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(jsonPayload)
        });

        if (!response.ok) {
          continue; // Skip to the next iteration on failure
        }

        const data = await response.json();
        // Extract the lecture audio and text pairs
        const { audio_url, lecture_text, subtitle_path } = data;
          this.lectureParts.push({
            moduleTitle: this.sections[index].module_name,
            subModuleTitle: subModules[this.subModule],
            text: lecture_text,
            audio: `${environment.AWS_MICRO_SERVICE_API}${audio_url}`, // Ensure proper URL concatenation
            subtitleUrl: subtitle_path
          });
          

          this.subModule++;
        }

        // this.currentLectureIndex = 0; // Reset the lecture index to the first module
        this.cdr.detectChanges(); // Trigger change detection
      } catch (error) {
        console.error(`Error processing section "${subModules[this.subModule]}":`, error);
      }
    }
  }


  isAudioVisible: boolean = false;
  isTextVisible: boolean = false;
  async displayLectureModule(moduleNumber: number): Promise<void> {
    this.currentLectureIndex = moduleNumber;
    this.aiTalk = true;
    this.lectureStarted = true;
    const currentPart = this.lectureParts[this.currentLectureIndex];

    this.currentLectureAudio = currentPart.audio;
    this.isRecordingIntroduction = false;
    this.cdr.detectChanges();
    if (this.currentLectureAudio) {
      // Show the lecture text and hide audio
      this.isAudioVisible = false;
      this.isTextVisible = true;
      if(this.lectureAudioElement && !this.lectureAudioElement.ended) {
        this.pauseLecture();
        this.lectureAudioElement.currentTime = 0;
      }
      this.lectureAudioElement = new Audio(this.currentLectureAudio);
      this.aiTalk = true;
      this.cdr.detectChanges();
      await this.loadSubtitles(currentPart.subtitleUrl);
      this.removeEventListenerForSubtitle(this.lectureAudioElement);
      this.lectureAudioElement.play();
      this.addEventListenerForSubtitle(this.lectureAudioElement);
      this.lectureAudioElement.onended = () => {
        this.aiTalk = false;
        this.isTextVisible = false;  // Hide text after audio finishes
        this.isAudioVisible = true;  // Optionally, show audio player again
        this.currentLectureIndex++;
        if (this.currentLectureIndex < this.lectureParts.length) {
          this.changeLecture(this.currentLectureIndex);
        } else {
          this.endCourse();
        }
      };
    }
  }

  continueCourse(): void {
    this.pauseQueryResponse();
    const queryAudio = "assets/audio/no_reply_raveena.mp3";
    this.paused = false;
    this.replyResponse(queryAudio, false, null);
  }

  haveDoubts(): void {
    const queryAudio = "assets/audio/yes_reply.mp3";
    this.replyResponse(queryAudio, true, null);
    this.isQueryFinished = false;
  }

  changeLecture(lectureNumber: number): void {
    if (this.lectureAudioElement) {
      this.pauseLecture();
      this.cdr.detectChanges();

      const sessionPause = "assets/audio/play_after_each_session_end_raveena.mp3";
      this.replyResponse(sessionPause, false, lectureNumber);
    }
  }

  // Method to end the course, stop audio, reset flags
  endCourse(): void {
    if (this.lectureAudioElement && !this.lectureAudioElement.ended) {
      this.pauseLecture();
      this.lectureAudioElement.currentTime = 0; // Reset the audio position to the start
    }
    this.aiTalk = false;
    this.currentLectureIndex = 0; // Reset lecture index to the beginning
    this.currentLectureAudio = null;
    this.isQueryRecording = false; // Disable query recording if any
    this.lectureStarted = false;
    this.isTextVisible = false;
    this.isRecordingIntroductionAllowed = false;
    this.startLectureClicked = false;
    this.cdr.detectChanges();
  }

  async replyResponse(audioFile: string, haveDoubts: boolean, lectureNumber: number) {
    if (this.replyAudioElement && !this.replyAudioElement.ended) {
      this.replyAudioElement.pause();
      this.replyAudioElement.currentTime = 0;  // Optionally, reset the audio to the beginning
    }
    this.replyAudioElement = null;
    this.replyAudioElement = new Audio(audioFile);
    this.pauseQueryResponse();

    this.aiTalk = true;
    this.cdr.detectChanges();
    this.replyAudioElement.play();
    this.replyAudioElement.onended = async () => {
      this.aiTalk = false;
      if (!haveDoubts) {
        if (lectureNumber == null) {
          if (this.lectureAudioElement) {
            this.aiTalk = true;
            this.lectureAudioElement.currentTime = this.lecturePausedTime;
            this.cdr.detectChanges();
            this.removeEventListenerForSubtitle(this.lectureAudioElement);
            this.lectureAudioElement.play();
            this.addEventListenerForSubtitle(this.lectureAudioElement);

            this.lectureAudioElement.onended = () => {
              this.currentLectureIndex++;
              if (this.currentLectureIndex < this.lectureParts.length) {
                this.displayLectureModule(this.currentLectureIndex);
              } else {
                this.endCourse(); // End the course once all parts are completed
              }
            };
          }
        } else {

          const filterChatMessages = this.chatMessages.filter(p => p.isSent && !p.visited);
          let chatIndex = 0;
          if (filterChatMessages.length > 0) {
            await this.sendAudioToApi(null, filterChatMessages[chatIndex], false, lectureNumber, false);
            // filterChatMessages[chatIndex].visited = true;
          } else {
            if (lectureNumber < this.lectureParts.length) {
              this.currentLectureIndex = lectureNumber;
            }
            if (this.currentLectureIndex < this.lectureParts.length) {
              this.displayLectureModule(this.currentLectureIndex);
            } else {
              this.endCourse();
            }
          }

        }
      }
    };
  }

  playQueryResponse(queryAudio: string, queryType: QueryType, lectureNumber: number, chatQuery: ChatMessage | null): void {
    if (!queryAudio) {
      this.aiTalk = false;
      this.isQueryFinished = true;
      return;
    }
    if (this.queryAudioElement) {
      this.queryAudioElement.pause();
      this.queryAudioElement.src = ""; // Clear the source to free memory
      this.queryAudioElement = null;
    }

    console.log("Query Audio path - " + queryAudio);
    this.queryAudioElement = new Audio(queryAudio);
    this.pauseLecture();
    this.isQueryFinished = false;
    this.aiTalk = true;
    this.cdr.detectChanges();

    this.queryAudioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
      this.aiTalk = false;
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    });

    this.queryAudioElement.onended = async () => {
      this.aiTalk = false;
      this.isRecordingIntroduction = true;
      this.isQueryFinished = true;
      this.queryAudioElement = null;
      this.cdr.detectChanges();
  
      if (queryType === QueryType.Intro || queryType === QueryType.Brief || queryType === QueryType.ChatDoubt) {
        let confirmation = await this.getUserConfirmation();
  
        while (!confirmation) {
          confirmation = await this.getUserConfirmation();
        }
   
        if (queryType === QueryType.Intro) {
          this.playLectureBrief();
        } else if (queryType === QueryType.Brief) {
          this.agendaTextVisible = false;
          const startSessionAudio = "assets/audio/let_start_session_raveena.mp3";
          this.playQueryResponse(startSessionAudio, QueryType.FinalCall, 0, null);
        } else if (queryType === QueryType.ChatDoubt && chatQuery) {
          chatQuery.visited = confirmation;
          const sessionPauseAudio = "assets/audio/let_move_to_next_question_raveena.mp3";
          this.replyResponse(sessionPauseAudio, false, lectureNumber);
        }
      } else if (queryType === QueryType.FinalCall) {
        this.displayLectureModule(this.currentLectureIndex);
      }
    };
  }
  

  async getUserConfirmation(): Promise<boolean> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return false;
    }

    return new Promise(async (resolve, reject) => {
      try {
        this.isRecordingIntroduction = true;

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.introRecorder = new MediaRecorder(stream);

        this.audioChunks = []; // Clear previous audio chunks

        this.introRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.introRecorder.onstop = async () => {
          try {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            this.audioChunks = [];
            this.queryAudio = URL.createObjectURL(audioBlob);
            this.isRecordingIntroduction = false;
            this.introRecorder = null;
            this.cdr.detectChanges();

            // Send audio to API and wait for response
            await this.sendAudioToApi(audioBlob, null, false, 0, true);


            resolve(this.userConfirmed); // Resolve the promise with the confirmation result
          } catch (error) {
            console.error('Error processing audio:', error);
            resolve(false); // Resolve with false in case of error
          }
        };

        this.introRecorder.start();
      } catch (error) {
        console.error('Error accessing microphone:', error);
        this.isRecordingIntroduction = false;
        reject(error); // Reject the promise in case of an error
      }
    });
  }


  sanitizeSsmlText(text: string): string {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  agendaText: string = null;
  agendaTextVisible: boolean = false;
  async playLectureBrief(): Promise<void> {
    this.isProcessing = true;
    this.cdr.detectChanges();
    const sanitizedTopics = this.sections.map(section => this.sanitizeSsmlText(section.module_name));

    const jsonPayload = {
      topics: sanitizedTopics,
    };
    const response = await fetch(
      `${environment.AWS_MICRO_SERVICE_API}lecture_start_instruction`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonPayload)
      }
    );

    if (response.ok) {
      const data = await response.json();
      this.queryAudio = environment.AWS_MICRO_SERVICE_API + data.audio_url || '';
      this.subtitleUrl = data.subtitle_path;
      this.agendaText = data.lecture_introduction_text;
      this.agendaTextVisible = true;
      this.isTextVisible = true;
      this.isProcessing = false;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
      await this.loadSubtitles(this.subtitleUrl);
      this.removeEventListenerForSubtitle(this.queryAudioElement);
      this.playQueryResponse(this.queryAudio, QueryType.Brief, 0, null);
      this.addEventListenerForSubtitle(this.queryAudioElement);
    }

  }

  pauseQueryResponse() {
    if (this.queryAudioElement && !this.queryAudioElement.paused) {
      this.queryAudioElement.pause();
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    }
  }
  paused = false;
  pauseLecture(): void {
    if (this.lectureAudioElement) {
      this.lecturePausedTime = this.lectureAudioElement.currentTime;
      this.lectureAudioElement.pause();
      this.aiTalk = false;
      this.paused = true;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    if (this.loaderInterval) clearInterval(this.loaderInterval);
  }

  removeEventListenerForSubtitle(audioElement: HTMLAudioElement) {
    if (audioElement) {
      audioElement.removeEventListener('timeupdate', () => this.onTimeUpdate(audioElement));
    }
  }

  addEventListenerForSubtitle(audioElement: HTMLAudioElement) {
    if(audioElement) {
      audioElement.addEventListener('timeupdate', () => this.onTimeUpdate(audioElement));
    }
  }

  sendMessage(): void {
    if (this.userMessage && this.userMessage.trim()) {
      this.chatMessages.push({
        text: this.userMessage.trim(),
        type: "text",
        isSent: true,
        replied: false,
        visited: false,
        repliedTo: null
      });

      this.userMessage = '';
      this.scrollToBottom();
    }
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.chatChannel) {
        this.chatChannel.nativeElement.scrollTop = this.chatChannel.nativeElement.scrollHeight;
      }
    }, 0);
  }

  /**
   * Subtitle code
   */
  async fetchSubtitles(url: string): Promise<string> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch subtitles: ${response.statusText}`);
    }
    return await response.text();
  }


  private timeToMilliseconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':');
    const [sec, millis] = seconds.split(',');
    return (
      parseInt(hours) * 3600000 +
      parseInt(minutes) * 60000 +
      parseInt(sec) * 1000 +
      parseInt(millis)
    );
  }

  subtitles: Subtitle[] = [];
  currentSubtitle: string = '';

  async loadSubtitles(fileName: string): Promise<void> {
    try {
      const response = await fetch("https://ai.prwatech.com/download/" + fileName);
      if (!response.ok) {
        throw new Error(`Failed to fetch subtitles: ${response.statusText}`);
      }
      const srtContent = await response.text();
      this.subtitles = this.parseSRT(srtContent);
    } catch (error) {
      console.error('Error loading subtitles:', error);
    }
  }

  parseSRT(srtContent: string): Subtitle[] {
    const subtitles: Subtitle[] = [];
    const srtBlocks = srtContent.split('\n\n');
    let wordBuffer: string[] = [];
    let startTime: number | null = null;
  
    for (const block of srtBlocks) {
      const lines = block.split('\n');
      if (lines.length >= 2) {
        const timeMatch = lines[1].match(
          /(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})/
        );
        if (timeMatch) {
          const word = lines[2]; // The word in this subtitle block
          const currentStartTime = this.timeToMilliseconds(timeMatch[1]);
          const currentEndTime = this.timeToMilliseconds(timeMatch[2]);
  
          // Initialize the start time for the first word in the group
          if (startTime === null) startTime = currentStartTime;
  
          wordBuffer.push(word);
  
          // Check if the buffer has enough words (10-12) or is at the last block
          if (
            wordBuffer.length >= 12 || // Enough words for a chunk
            block === srtBlocks[srtBlocks.length - 1] // Last block
          ) {
            subtitles.push({
              startTime: startTime,
              endTime: currentEndTime, // Use the end time of the last word in the group
              text: wordBuffer.join(' '), // Combine words into a sentence
            });
  
            // Reset for the next group
            wordBuffer = [];
            startTime = null;
          }
        }
      }
    }
  
    // Handle remaining words in the buffer
    if (wordBuffer.length > 0 && startTime !== null) {
      const lastEndTime = subtitles.length > 0 ? subtitles[subtitles.length - 1].endTime : 0;
      subtitles.push({
        startTime: startTime,
        endTime: lastEndTime + 2000, // Approximate end time for the last group
        text: wordBuffer.join(' '),
      });
    }
  
    return subtitles;
  }
  
  onTimeUpdate(audioElement: HTMLAudioElement): void {
    const currentTime = audioElement.currentTime * 1000; // Convert to milliseconds
    const subtitle = this.subtitles.find(
      (sub) => currentTime >= sub.startTime && currentTime < sub.endTime
    );
  
    this.currentSubtitle = subtitle ? subtitle.text : '';
    // if (subtitle) {
    //   const lines = subtitle.text.split('.'); // Split subtitle text into individual lines
    //   const elapsed = currentTime - subtitle.startTime; // Time elapsed since the subtitle started
    //   const duration = subtitle.endTime - subtitle.startTime; // Total duration of the subtitle
    //   const lineIndex = Math.floor((elapsed / duration) * lines.length); // Determine the current line to show
    //   this.currentSubtitle = lines[lineIndex] ? lines[lineIndex].trim() : '';
    // } else {
    //   this.currentSubtitle = '';
    // }
  }

  openIframeTab(url: string): void {
    window.open(url, '_blank');
  }
  
}
