import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryType } from '../interface/query-type.enum';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent {
  @ViewChild('chatChannel', { static: false }) chatChannel!: ElementRef; // Correctly include the static option
  // Variables for Introduction
  introductionAudioUrl: string = '';
  isRecordingIntroductionAllowed: boolean = false;
  isRecordingIntroduction: boolean = false;
  loaderMessage: string = 'Analyzing text...';
  aiTalk: boolean = false;

  lectureStarted: boolean = false;
  selectedCourse: string = '';
  isQueryRecording: boolean = false;
  erroResponse: boolean = false;
  queryAudio: string = '';

  errorMessageText: string = '';
  mediaRecorder: MediaRecorder | null = null;
  introRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  isQueryFinished: boolean = false;
  lecturePausedTime: number = 0;
  lectureAudioElement: HTMLAudioElement | null = null;
  queryAudioElement: HTMLAudioElement | null = null;
  replyAudioElement: HTMLAudioElement | null = null;
  isProcessing: boolean = false;
  chatMessages: ChatMessage[] = [];
  loaderTextIndex: number = 0;

  queryLoaderMessages: string[] = [
    'Processing your audio...',
    'Understanding your query...',
    'Generating a response...',
    'Analyzing audio data...'
  ];
  sectionsImageMap: string[] = [
    'assets/tutor/answer_1.png',
    'assets/tutor/answer_2.png',
    'assets/tutor/answer_3.png'
  ]
  sections: string[] = [
    "What is Python",
    // "Python Environment Setup & Essentials",
    "Python Basics: Language Constructs",
    "Operators in Python"
    // "Working with Lists",
    // "Understanding Tuples",
    // "Sets and Frozen Sets",
    // "Dictionaries in Python",
    // "Program Control Flow in Python",
    // "NumPy: Mathematical Computing",
    // "SciPy: Scientific Computing",
    // "Matplotlib: Data Visualization",
    // "Pandas: Data Analysis and Machine Learning"
  ];

  userMessage: string = '';

  // New variables for lecture content
  currentLectureIndex: number = 0;
  lectureParts: { audio: string; text: string; moduleTitle: string }[] = [];
  currentLectureAudio: string | null = null;

  private loaderInterval: any;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  onCourseSelect(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedCourse = selectedValue;
  }


  async ngOnInit() {
    try {
      const response = await fetch(
        `${environment.AWS_MICRO_SERVICE_API}introduce_tutor`,
        { method: 'POST' }
      );
      if (response.ok) {
        const data = await response.json();

        // Extract the introduction audio and text
        const { audio_url, introduction_text } = data;
        this.introductionAudioUrl =
          environment.AWS_MICRO_SERVICE_API + audio_url || '';
      } else {
        console.error('Failed to fetch introduction tutor data:', response.statusText);
      }
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
    }
  }

  async playIntroduction(): Promise<void> {
    this.pauseLecture();
    this.playQueryResponse(this.introductionAudioUrl, QueryType.Intro, 0, null);
    this.cdr.detectChanges();
    // Wait for introduction audio to finish
    if (this.queryAudioElement) {
      this.queryAudioElement.onended = () => {
        this.isRecordingIntroductionAllowed = true;
        this.isRecordingIntroduction = false;
        this.aiTalk = false;
        this.cdr.detectChanges();
        this.startSelfIntro();
      };
    }
  }

  async startRecording(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.pauseLecture();
      this.isQueryRecording = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.chatMessages.push({
          text: "AUDIO FILE UPLOADED",
          type: "audio",
          isSent: true,
          replied: false,
          visited: false,
          repliedTo: null
        });
        this.scrollToBottom();
        this.cdr.detectChanges();

        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, false, this.currentLectureIndex, false);
        this.isQueryRecording = false;
        this.isRecordingIntroduction = false;
        this.isRecordingIntroductionAllowed = false;
        this.cdr.detectChanges();
      };

      this.mediaRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isQueryRecording = false;
    }
  }

  async startSelfIntro(): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return;
    }

    try {
      this.isRecordingIntroduction = true;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.introRecorder = new MediaRecorder(stream);

      this.introRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.introRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
        this.audioChunks = [];
        this.queryAudio = URL.createObjectURL(audioBlob);
        await this.sendAudioToApi(audioBlob, null, true, 0, false);
        this.isRecordingIntroduction = false;
        this.introRecorder = null;

        this.cdr.detectChanges();
      };

      this.introRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
      this.isRecordingIntroduction = false;
    }
  }

  stopIntroRecording(): void {
    if (this.introRecorder && this.introRecorder.state === 'recording') {
      // this.isRecordingIntroductionAllowed = false;
      this.isRecordingIntroduction = false;
      this.introRecorder.stop();
    }
  }

  stopRecording(): void {
    this.isQueryRecording = false;
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.stop();
    }
  }
  userConfirmed: boolean = false;
  async sendAudioToApi(audioBlob: Blob, chatQuery: ChatMessage, isForIntro: boolean, lectureNumber: number, userConfirmation: boolean): Promise<void> {
    try {
      this.isProcessing = true;
      this.cdr.detectChanges();
      const formData = new FormData();
      let bodyContent;
      let headers = {};
      if (audioBlob != null) {
        formData.append('audio', audioBlob, 'query_' + Date.now() + '.webm');
        bodyContent = formData;
      } else {
        const jsonPayload = {
          query: chatQuery.text,
        };
        bodyContent = JSON.stringify(jsonPayload);
        headers['Content-Type'] = 'application/json';
      }

      const request = isForIntro
        ? `${environment.AWS_MICRO_SERVICE_API}get_user_name` :
        userConfirmation ? `${environment.AWS_MICRO_SERVICE_API}confirmation`
          : `${environment.AWS_MICRO_SERVICE_API}handle_query`;

      const response = await fetch(request, {
        method: 'POST',
        headers,
        body: bodyContent,
      });


      if (response.ok) {
        this.isProcessing = false;
        const data = await response.json();
        this.queryAudio = environment.AWS_MICRO_SERVICE_API + (data.audio_url || '');
        if (chatQuery != null && chatQuery.text != '') {
          this.chatMessages.push({
            replied: true,
            isSent: false,
            text: data.response_text || data.welcome_text || "Thanks for your input!",
            type: "text",
            visited: true,
            repliedTo: chatQuery.text
          });
        }


        this.userConfirmed = false; //reset
        if (userConfirmation) {
          this.userConfirmed = data.response_text;
        }

        this.scrollToBottom();
        this.cdr.detectChanges();
        if (!this.userConfirmed) {
          this.userConfirmed = false;
          await this.playQueryResponse(this.queryAudio, isForIntro ? QueryType.Intro : QueryType.ChatDoubt, lectureNumber, chatQuery);
        }
        this.queryAudio = '';
        this.cdr.detectChanges();
      } else {
        this.isProcessing = false;
        console.error('API error:', response.statusText);
      }
    } catch (error) {
      this.errorMessageText = `Failed to generate response. Please try again.`;
      this.erroResponse = true;
      this.isProcessing = false;
      this.cdr.detectChanges();
      console.error('Failed to send audio to API:', error);
    }
  }
  startLectureClicked = false;
  async startLecture(): Promise<void> {
    this.startLectureClicked = true;
    this.playIntroduction();
    this.cdr.detectChanges();
    this.generateLectureParts(0, this.sections.length);
  }

  async generateLectureParts(startIndex: number, endIndex: number): Promise<void> {
    for (let index = startIndex; index < endIndex; index++) {
      const section = this.sections[index];

      try {
        const response = await fetch(`${environment.AWS_MICRO_SERVICE_API}generate_lecture`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ section })
        });

        if (!response.ok) {
          console.error(`Error fetching lecture for section "${section}":`, response.statusText);
          continue; // Skip to the next iteration on failure
        }

        const data = await response.json();

        // Extract the lecture audio and text pairs
        const { audio_url, lecture_text } = data;

        this.lectureParts.push({
          moduleTitle: section,
          text: lecture_text,
          audio: `${environment.AWS_MICRO_SERVICE_API}${audio_url}`, // Ensure proper URL concatenation
        });

        this.currentLectureIndex = 0; // Reset the lecture index to the first module
        this.cdr.detectChanges(); // Trigger change detection
      } catch (error) {
        console.error(`Error processing section "${section}":`, error);
      }
    }
  }


  isAudioVisible: boolean = false;
  isTextVisible: boolean = false;
  displayLectureModule(moduleNumber: number): void {
    this.currentLectureIndex = moduleNumber;
    this.aiTalk = true;
    this.lectureStarted = true;
    const currentPart = this.lectureParts[this.currentLectureIndex];

    this.currentLectureAudio = currentPart.audio;
    this.isRecordingIntroduction = false;
    this.cdr.detectChanges();
    if (this.currentLectureAudio) {
      // Show the lecture text and hide audio
      this.isAudioVisible = false;
      this.isTextVisible = true;
      this.lectureAudioElement = null;
      this.lectureAudioElement = new Audio(this.currentLectureAudio);
      this.aiTalk = true;
      this.cdr.detectChanges();
      this.lectureAudioElement.play();
      this.lectureAudioElement.onended = () => {
        this.aiTalk = false;
        this.isTextVisible = false;  // Hide text after audio finishes
        this.isAudioVisible = true;  // Optionally, show audio player again
        this.currentLectureIndex++;
        if (this.currentLectureIndex < this.lectureParts.length) {
          this.changeLecture(this.currentLectureIndex);
        } else {
          this.endCourse();
        }
      };
    }
  }

  continueCourse(): void {
    this.pauseQueryResponse();
    const queryAudio = "assets/audio/no_reply_raveena.mp3";
    this.paused = false;
    this.replyResponse(queryAudio, false, null);
  }

  haveDoubts(): void {
    const queryAudio = "assets/audio/yes_reply.mp3";
    this.replyResponse(queryAudio, true, null);
    this.isQueryFinished = false;
  }

  changeLecture(lectureNumber: number): void {
    if (this.lectureAudioElement) {
      this.pauseLecture();
      this.cdr.detectChanges();

      const sessionPause = "assets/audio/play_after_each_session_end_raveena.mp3";
      this.replyResponse(sessionPause, false, lectureNumber);
    }
  }

  // Method to end the course, stop audio, reset flags
  endCourse(): void {
    if (this.lectureAudioElement) {
      this.pauseLecture();
      this.lectureAudioElement.currentTime = 0; // Reset the audio position to the start
    }
    this.aiTalk = false;
    this.currentLectureIndex = 0; // Reset lecture index to the beginning
    this.currentLectureAudio = null;
    this.isQueryRecording = false; // Disable query recording if any
    this.lectureStarted = false;
    this.isTextVisible = false;
    this.isRecordingIntroductionAllowed = false;
    this.startLectureClicked = false;
    this.cdr.detectChanges();
  }

  async replyResponse(audioFile: string, haveDoubts: boolean, lectureNumber: number) {
    this.replyAudioElement = null;
    this.replyAudioElement = new Audio(audioFile);
    this.pauseQueryResponse();

    this.aiTalk = true;
    this.cdr.detectChanges();
    this.replyAudioElement.play();
    this.replyAudioElement.onended = async () => {
      this.aiTalk = false;
      if (!haveDoubts) {
        if (lectureNumber == null) {
          if (this.lectureAudioElement) {
            this.aiTalk = true;
            this.lectureAudioElement.currentTime = this.lecturePausedTime;
            this.cdr.detectChanges();
            this.lectureAudioElement.play();

            this.lectureAudioElement.onended = () => {
              this.currentLectureIndex++;
              if (this.currentLectureIndex < this.lectureParts.length) {
                this.displayLectureModule(this.currentLectureIndex);
              } else {
                this.endCourse(); // End the course once all parts are completed
              }
            };
          }
        } else {

          const filterChatMessages = this.chatMessages.filter(p => p.isSent && !p.visited);
          let chatIndex = 0;
          if (filterChatMessages.length > 0) {
            await this.sendAudioToApi(null, filterChatMessages[chatIndex], false, lectureNumber, false);
            // filterChatMessages[chatIndex].visited = true;
          } else {
            if (lectureNumber < this.lectureParts.length) {
              this.currentLectureIndex = lectureNumber;
            }
            if (this.currentLectureIndex < this.lectureParts.length) {
              this.displayLectureModule(this.currentLectureIndex);
            } else {
              this.endCourse();
            }
          }

        }
      }
    };
  }

  playQueryResponse(queryAudio: string, queryType: QueryType, lectureNumber: number, chatQuery: ChatMessage | null): void {
    if (!queryAudio) {
      this.aiTalk = false;
      this.isQueryFinished = true;
      return;
    }
    if (this.queryAudioElement) {
      this.queryAudioElement.pause();
      this.queryAudioElement.src = ""; // Clear the source to free memory
      this.queryAudioElement = null;
    }

    console.log("Query Audio path - " + queryAudio);
    this.queryAudioElement = new Audio(queryAudio);
    this.pauseLecture();
    this.isQueryFinished = false;
    this.aiTalk = true;
    this.cdr.detectChanges();

    this.queryAudioElement.play().catch((error) => {
      console.error("Error playing audio:", error);
      this.aiTalk = false;
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    });

    this.queryAudioElement.onended = async () => {
      this.aiTalk = false;
      this.isRecordingIntroduction = true;
      this.isQueryFinished = true;
      this.queryAudioElement = null;
      this.cdr.detectChanges();
  
      if (queryType === QueryType.Intro || queryType === QueryType.Brief || queryType === QueryType.ChatDoubt) {
        let confirmation = await this.getUserConfirmation();
  
        while (!confirmation) {
          confirmation = await this.getUserConfirmation();
        }
   
        if (queryType === QueryType.Intro) {
          this.playLectureBrief();
        } else if (queryType === QueryType.Brief) {
          this.agendaTextVisible = false;
          const startSessionAudio = "assets/audio/let_start_session_raveena.mp3";
          this.playQueryResponse(startSessionAudio, QueryType.FinalCall, 0, null);
        } else if (queryType === QueryType.ChatDoubt && chatQuery) {
          chatQuery.visited = confirmation;
          const sessionPauseAudio = "assets/audio/let_move_to_next_question_raveena.mp3";
          this.replyResponse(sessionPauseAudio, false, lectureNumber);
        }
      } else if (queryType === QueryType.FinalCall) {
        this.displayLectureModule(this.currentLectureIndex);
      }
    };
  }
  

  async getUserConfirmation(): Promise<boolean> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording.');
      return false;
    }

    return new Promise(async (resolve, reject) => {
      try {
        this.isRecordingIntroduction = true;

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.introRecorder = new MediaRecorder(stream);

        this.audioChunks = []; // Clear previous audio chunks

        this.introRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.introRecorder.onstop = async () => {
          try {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            this.audioChunks = [];
            this.queryAudio = URL.createObjectURL(audioBlob);
            this.isRecordingIntroduction = false;
            this.introRecorder = null;
            this.cdr.detectChanges();

            // Send audio to API and wait for response
            await this.sendAudioToApi(audioBlob, null, false, 0, true);


            resolve(this.userConfirmed); // Resolve the promise with the confirmation result
          } catch (error) {
            console.error('Error processing audio:', error);
            resolve(false); // Resolve with false in case of error
          }
        };

        this.introRecorder.start();
      } catch (error) {
        console.error('Error accessing microphone:', error);
        this.isRecordingIntroduction = false;
        reject(error); // Reject the promise in case of an error
      }
    });
  }


  sanitizeSsmlText(text: string): string {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  agendaText: string = null;
  agendaTextVisible: boolean = false;
  async playLectureBrief(): Promise<void> {
    this.isProcessing = true;
    this.cdr.detectChanges();
    const sanitizedTopics = this.sections.map(section => this.sanitizeSsmlText(section));

    const jsonPayload = {
      topics: sanitizedTopics,
    };
    const response = await fetch(
      `${environment.AWS_MICRO_SERVICE_API}lecture_start_instruction`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(jsonPayload)
      }
    );

    if (response.ok) {
      const data = await response.json();
      this.queryAudio = environment.AWS_MICRO_SERVICE_API + data.audio_url || '';
      this.agendaText = data.lecture_introduction_text;
      this.agendaTextVisible = true;
      this.isTextVisible = true;
      this.isProcessing = false;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
      this.playQueryResponse(this.queryAudio, QueryType.Brief, 0, null);
    }

  }

  pauseQueryResponse() {
    if (this.queryAudioElement && !this.queryAudioElement.paused) {
      this.queryAudioElement.pause();
      this.isQueryFinished = true;
      this.cdr.detectChanges();
    }
  }
  paused = false;
  pauseLecture(): void {
    if (this.lectureAudioElement) {
      this.lecturePausedTime = this.lectureAudioElement.currentTime;
      this.lectureAudioElement.pause();
      this.aiTalk = false;
      this.paused = true;
      this.isQueryFinished = false;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    if (this.loaderInterval) clearInterval(this.loaderInterval);
  }

  sendMessage(): void {
    if (this.userMessage && this.userMessage.trim()) {
      this.chatMessages.push({
        text: this.userMessage.trim(),
        type: "text",
        isSent: true,
        replied: false,
        visited: false,
        repliedTo: null
      });

      this.userMessage = '';
      this.scrollToBottom();
    }
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.chatChannel) {
        this.chatChannel.nativeElement.scrollTop = this.chatChannel.nativeElement.scrollHeight;
      }
    }, 0);
  }
}
