import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-code-execution',
  templateUrl: './code-execution.component.html',
  styleUrls: ['./code-execution.component.css']
})
export class CodeExecutionComponent {
  private shouldScrollToGeneratedCode: boolean = false;
  private shouldScrollToDebuggedCode: boolean = false;

  @ViewChild('generatedCodeSection', {static:false}) generatedCodeSection!: ElementRef;
  @ViewChild('debuggedCodeSection', {static:false}) debuggedCodeSection!: ElementRef;

  generateCodeRequest: boolean = false;
  debugCodeRequest: boolean = false;
  query: string = '';            // User query for generating code
  generatedCode: string | null = null; // The generated Python code from API
  wrongCode: string = '';        // Wrong code for debugging
  debuggedCode: string | null = null; // The debugged Python code from API
  generatedCodeLines: string[] = [];
  // Method to generate Python code based on the user's query
  generatePythonCode() {
    if (this.query.trim()) {
      const apiUrl = 'https://ai.prwatech.com/generate_python_code'; // Code generation API URL
      this.generateCodeRequest = true;
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: this.query }), // Send the query as JSON
      })
        .then(response => {
          this.generateCodeRequest = false;
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.generatedCode = data.python_code; // Display generated Python code
          this.shouldScrollToGeneratedCode = true;
        })
        .catch(error => {
          console.error('Error generating code:', error);
          this.generatedCode = 'Error generating code: ' + error.message; // Handle errors
        });
    }
  }

  // Method to debug the provided Python code (wrong code)
  debugCode() {
    if (this.wrongCode.trim()) {
      const apiUrl = 'https://ai.prwatech.com/debug_python_code'; // Debug API URL
      this.debugCodeRequest = true;

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: this.wrongCode }), // Send the code to be debugged
      })
        .then(response => {
      this.debugCodeRequest = false;
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.debuggedCode = data.debugged_code; // Display debugged Python code
          this.shouldScrollToDebuggedCode = true;
        })
        .catch(error => {
          console.error('Error debugging code:', error);
          this.debuggedCode = 'Error debugging code: ' + error.message; // Handle errors
        });
    }
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToGeneratedCode && this.generatedCodeSection) {
      this.scrollToSection(this.generatedCodeSection);
      this.shouldScrollToGeneratedCode = false; // Reset the flag
    }

    if (this.shouldScrollToDebuggedCode && this.debuggedCodeSection) {
      this.scrollToSection(this.debuggedCodeSection);
      this.shouldScrollToDebuggedCode = false; // Reset the flag
    }
  }

  scrollToSection(section: ElementRef) {
    section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
