import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { RouterModule, Routes } from '@angular/router';
import { ChatTestComponent } from './chat-test/chat-test.component';
import { AiTutorComponent } from './ai-tutor/ai-tutor.component';


const routes: Routes = [
  { path: 'ai-tutor', component: ChatComponent }, // Route for ChatComponent
  { path: 'ai-tutor-test', component: AiTutorComponent }, // Route for ChatComponent

  // Add other routes here as needed
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrwatechAiRoutingModule { }
