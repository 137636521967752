import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { PrwatechAiRoutingModule } from './prwatech-ai-routing.module';
import { FormatTextPipe } from './chat/format-text.pipe';
import { FormsModule } from '@angular/forms';
import { ChatTestComponent } from './chat-test/chat-test.component';
import { CodeExecutionComponent } from './code-execution/code-execution.component';
import { AiTutorComponent } from './ai-tutor/ai-tutor.component';


@NgModule({
  declarations: [
    ChatComponent,
    FormatTextPipe,
    ChatTestComponent,
    CodeExecutionComponent,
    AiTutorComponent
    // other components
  ],
  imports: [
    CommonModule,
    PrwatechAiRoutingModule, // Add the routing module here,
    FormsModule
  ],
  exports: [
    ChatComponent // Export if you need to use it outside this module
  ]
})
export class PrwatechAiModule {}
